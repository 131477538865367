//site
$primary-h: 331.38 !default;
$primary-s: 56.52 !default;
$primary-b: 45.1 !default;

// $secondary: red;
// $tertiary: #fed505;
// $tertiary-text: blue;

// GDRP-BANNER RELATED
// $gdpr-accept-all: #005700 !default;
// $gdpr-toggle-checked: #005700 !default;
// $gdpr-toggle: #b22515 !default;
// $gdpr-toggle-border: #000 !default;
// $gdpr-focus-color: #ff9800 !default;
// $gdpr-link-color: #004285 !default;
// $gdpr-focus-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
//   0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem $gdpr-focus-color !default;

$header-slim-bg-color: #30373d;
$header-center-bg-color: white;
$enable-header-white-background: true;

//$link-color: hsl(210deg, 100%, 40%);
