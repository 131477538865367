.block.iconBlocks {
  .link-button {
    a.btn-tertiary {
      border: 1px solid transparent;
      background-color: #fff;
      color: $primary !important;

      &:hover {
        background-color: $primary;
        border-color: #fff;
        color: #fff !important;
      }
    }
  }
}
