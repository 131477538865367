.block.iconBlocks {
  .full-width {
    height: auto;
  }

  @include blocks-with-bg-edit-buttons();

  .background-image {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      min-width: 100%;
      max-width: unset;
      height: auto;
      min-height: 100%;
      transform: translate(-50%, -50%);
    }

    &:after {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(
        $primary,
        0.85
      ); // serve per fare la trasparenza sull'immagine di sfondo.
      content: '';
    }

    &.no-image {
      &:after {
        background-color: $primary;
      }
    }
  }

  .block-header {
    .title {
      margin: 1rem 0 2rem 0;
      color: #fff;
      font-size: 2.35rem;
      font-weight: bold;
      line-height: 3rem;
      text-align: center;
    }

    .description {
      margin-bottom: 2rem;
      color: #fff;
      line-height: 1.65em;
      text-align: center;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      a:not(.btn) {
        color: #fff;
      }
    }
  }

  .row > .col-lg-4 {
    margin-bottom: 1rem;
  }

  .single-block.subblock-edit {
    height: 100%;

    .card-wrapper {
      height: 100%;
    }

    &.subblock-draggable {
      transform: unset;
    }

    .link-form-container {
      .ui.input {
        > input {
          height: 2rem;
        }
      }
    }
  }

  .card {
    padding-top: 2.5rem;

    .iconblock-icon {
      margin-bottom: 2rem;
      text-align: center;

      .icon-placeholder {
        display: inline-block;
        width: 6rem;
        height: 6rem;
        padding: 0.5rem 1rem;
        border-radius: 100%;
        background-color: #ededed;
        font-size: 0.8rem;
        vertical-align: middle;
      }

      .icon {
        width: auto;
        height: 4rem;
        color: $tertiary;
      }
    }

    .iconblock-title {
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 1.8rem;
      text-align: center;
    }

    .iconblock-text {
      text-align: center;
    }

    a.read-more {
      left: 0;
      display: block;
      width: 100%;
      color: $gray-secondary;
      text-align: center;
    }
  }

  @media (max-width: #{map-get($grid-breakpoints, md)}) {
    .card {
      padding-top: 1rem;
    }

    .row > .col-lg-4 {
      margin-bottom: 0;
    }
  }
}
